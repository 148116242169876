import React, { useState, Fragment, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from "@material-ui/icons/Check";
import Stripe from "./Stripe";
import { AuthContext } from "../../providers/Auth";
import { Redirect } from "react-router-dom";

function SubscriptionOptions(props) {
  const { classes, operator } = props;
  const { currentUser } = useContext(AuthContext);
  const [selectedSubscription, setSelectedSubscription] = useState({
    name: "",
    price: 0,
    term: "",
    priceId: null,
    savings: "",
  });

  const subscriptionOptions = [
    {
      name: "Annual",
      price: 15.99,
      term: "year",
      priceId: "price_1I8vkKKIu9BpdiKEni59PeY9",
      savings:
        "Our MOST popular option. At less than half a cup of coffee per month the potential growth from this plan is UNBELIEVABLE!!",
    },
    {
      name: "Quarterly",
      price: 4.49,
      term: "quarter",
      priceId: "price_1I8vklKIu9BpdiKEHXyuz3Gs",
      savings:
        "Not ready to commit for the full 12 months... 3 months will certainly give you ample opportunity to test the power of the system.",
    },
    {
      name: "Monthly",
      price: 1.99,
      term: "month",
      priceId: "price_1I8vl1KIu9BpdiKERfbUGMzF",
      savings:
        "Imagine what $2 could get you... not much in the real world. But it can get you a WHOLE MONTH of spiritual development.",
    },
  ];

  if (!currentUser.emailVerified) {
    return <Redirect to="/settings/subscription" />;
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.headers}>
            Choose a Subscription Option
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {subscriptionOptions.map((subscription) => {
          return (
            <Grid item xs={12} md={4}>
              <Card
                variant={"outlined"}
                raised={true}
                onClick={() => setSelectedSubscription(subscription)}
              >
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h4"
                        data-cy={`card-header-${subscription.term}`}
                      >
                        {`$${subscription.price.toFixed(2)} AUD per ${
                          subscription.term
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {`${subscription.savings}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  {subscription.priceId === selectedSubscription.priceId ? (
                    <Button
                      size="small"
                      color="primary"
                      data-cy={`card-selected-${subscription.term}`}
                    >
                      <CheckIcon /> Selected! Scroll down.
                    </Button>
                  ) : null}
                </CardActions>
              </Card>
              {subscription.priceId === selectedSubscription.priceId ? (
                <Typography variant="caption">
                  Subscriptions are non-refundable BUT you can cancel
                  auto-renewal at ANY time. No Questions Asked! Your trial will
                  end when you purchase a subscription.
                </Typography>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
      {selectedSubscription.priceId !== null ? (
        <Grid container id="paymentForm">
          <Stripe
            operator={operator}
            selectedSubscription={selectedSubscription}
          />
        </Grid>
      ) : null}
    </Fragment>
  );
}

const styles = (theme) => ({
  headers: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
});

export default withStyles(styles)(SubscriptionOptions);
