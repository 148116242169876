import React, { Fragment, useContext, useState } from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { AuthContext } from "../../providers/Auth";
import firebase from "../../config/firebase";
import MuiAlert from "@material-ui/lab/Alert";
import { red } from "@material-ui/core/colors";

function UserSettings(props) {
  const { classes } = props;
  const { currentUser } = useContext(AuthContext);

  const initialUserDetails = {
    uuid: currentUser.uid,
    name: currentUser.displayName,
    email: currentUser.email,
    photoUrl: "",
    password: "",
    confirmPassword: "",
    changesArray: [],
    deleteAccount: false,
    billingConfirmation: false,
  };

  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [formChanged, setFormChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ text: "", severity: "", open: false });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const redTheme = createMuiTheme({ palette: { primary: red } });

  const handleFormChange = (event) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value });
    setFormChanged(true);
  };

  const handleConfirmTextUpdate = (event) => {
    setConfirmText(event.target.value);
  };

  const resetAllChanges = () => {
    setLoading(true);
    setUserDetails(initialUserDetails);
    setFormChanged(false);
    setLoading(false);
  };

  const confirmChange = () => {
    setLoading(true);
    let tempArray = [];

    if (currentUser.displayName !== userDetails.name) {
      if (userDetails.name.length < 1) {
        setAlert({
          text: "Ah! Your name is required",
          severity: "error",
          open: true,
        });
        setLoading(false);
        return;
      } else {
        tempArray.push("name");
      }
    }

    if (currentUser.email !== userDetails.email) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(String(userDetails.email).toLowerCase())) {
        setAlert({
          text:
            "Are you sure your email address is correct? Seems invalid to me.",
          severity: "error",
          open: true,
        });
        setLoading(false);
        return;
      } else {
        tempArray.push("email");
      }
    }

    if (userDetails.password !== "") {
      if (userDetails.password.length < 6) {
        setAlert({
          text: "Password too short. It must be greater than 6 characters.",
          severity: "error",
          open: true,
        });
        setLoading(false);
        return;
      } else if (userDetails.password !== userDetails.confirmPassword) {
        setAlert({
          text:
            "Both password fields must match. Passwords are case sensitive.",
          severity: "error",
          open: true,
        });
        setLoading(false);
        return;
      } else {
        tempArray.push("password");
      }
    }

    if (tempArray.length !== 0) {
      setUserDetails({ ...userDetails, changesArray: tempArray });
      setOpenConfirm(true);
    }
    console.log(currentUser);
  };

  const confirmDelete = () => {
    setLoading(true);
    setOpenDeleteConfirm(true);
  };

  const deleteAccount = () => {
    const deleteUser = firebase.functions().httpsCallable("deleteUser");
    deleteUser()
      .then((response) => {
        setAlert({ text: response.data, severity: "success", open: true });
        setLoading(false);
        setTimeout(() => {
          firebase.auth().signOut();
        }, 8000);
      })
      .catch((error) => {
        setAlert({ text: error.message, severity: "error", open: true });
        setLoading(false);
      });
  };

  const makeChanges = async () => {
    dialogClose();
    const updateUser = firebase.functions().httpsCallable("updateUser");
    updateUser(userDetails)
      .then(() => {
        setAlert({
          text:
            "Awesome! Your details have been updated. You will be logged out momentarily.",
          severity: "success",
          open: true,
        });
        setLoading(false);
        setTimeout(() => {
          firebase.auth().signOut();
        }, 8000);
      })
      .catch((error) => {
        setAlert({ text: error.message, severity: "error", open: true });
        setLoading(false);
      });
  };

  const closeAlert = () => {
    setAlert({ text: "", severity: "", open: false });
  };

  const dialogClose = () => {
    setOpenConfirm(false);
    setOpenDeleteConfirm(false);
    setConfirmText("");
    setLoading(false);
  };

  return (
    <Fragment>
      <Typography variant="h6" className={classes.headers} data-cy="header">
        Account Settings
      </Typography>
      <Typography variant="body1" className={classes.bodyText} data-cy="body1">
        Would you like to update your details? Below are the details that we
        have in our database. If any need to be corrected, then please update
        the details in the form and press submit. Your password is not shown for
        security reasons but can be updated.
      </Typography>
      <Typography variant="body1" className={classes.bodyText} data-cy="body2">
        After successfully changing your profile you WILL need to be logged out
        to correctly update the device settings. Please log back in to continue
        using the app.
      </Typography>
      <form
        className={classes.form}
        onSubmit={(e) => e.preventDefault() && false}
      >
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">Name</InputLabel>
          <Input
            data-cy="name-input"
            id="name"
            name="name"
            autoComplete="off"
            value={userDetails.name}
            onChange={(e) => handleFormChange(e)}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input
            data-cy="email-input"
            type="email"
            id="email"
            name="email"
            autoComplete="off"
            value={userDetails.email}
            onChange={(e) => handleFormChange(e)}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            data-cy="password-input"
            name="password"
            type="password"
            id="password"
            autoComplete="off"
            value={userDetails.password}
            onChange={(e) => handleFormChange(e)}
            required
          />
          {userDetails.password.length < 6 &&
          userDetails.password.length > 0 ? (
            <Typography variant="caption" className={classes.hintText}>
              Password must be longer than 6 characters
            </Typography>
          ) : null}
        </FormControl>
        {userDetails.password.length > 0 ? (
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
            <Input
              name="confirmPassword"
              type="password"
              id="confirmPassword"
              autoComplete="off"
              value={userDetails.confirmPassword}
              onChange={(e) => handleFormChange(e)}
              required
            />
            {userDetails.password !== userDetails.confirmPassword &&
            userDetails.confirmPassword.length > 5 ? (
              <Typography variant="caption" className={classes.hintText}>
                Passwords must match
              </Typography>
            ) : null}
          </FormControl>
        ) : null}
        {formChanged ? (
          <Fragment>
            <Button
              type="button"
              data-cy="change-button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={confirmChange}
              className={classes.submit}
              disabled={loading}
            >
              {loading ? "Please wait..." : "Submit"}
            </Button>
            <Button
              data-cy="cancel-change-button"
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={resetAllChanges}
              className={classes.submit}
              disabled={loading}
            >
              {loading ? "Please wait..." : "Cancel"}
            </Button>{" "}
          </Fragment>
        ) : null}
      </form>
      <Typography variant="h6" className={classes.headers} data-cy="header2">
        Delete Account
      </Typography>
      <Typography variant="body1" className={classes.bodyText} data-cy="body3">
        You may delete your account using the button below. Please be aware that
        this is irrevocable... there's no going back from this option.
      </Typography>
      <Typography variant="body1" className={classes.bodyText} data-cy="body4">
        Please be aware that if you currently have a valid subscription, then
        subscription will be cancelled at the end of the current billing period.
        Refunds for unused portions of the billing period are not possible.
      </Typography>
      <MuiThemeProvider theme={redTheme}>
        <Button
          type="button"
          data-cy="delete-button"
          variant="contained"
          color="primary"
          onClick={confirmDelete}
          className={classes.submit}
          disabled={loading}
        >
          {loading ? "Please wait..." : "Delete Account"}
        </Button>
      </MuiThemeProvider>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          data-cy="settingsAlert"
          severity={alert.severity}
          variant="filled"
          onClose={closeAlert}
        >
          {alert.text}
        </MuiAlert>
      </Snackbar>
      {/*Account Changes Dialog*/}
      <Dialog
        open={openConfirm}
        onClose={dialogClose}
        aria-labelledby="confirm-changes-dialog"
      >
        <DialogTitle id="form-dialog-title">Confirm Changes</DialogTitle>
        <DialogContent>
          <DialogContentText data-cy="dialog-content-text">
            To confirm the changes, please type 'confirm' in the box below. Once
            the changes are accepted, you will be automatically logged out.
          </DialogContentText>
          <TextField
            data-cy="confirm-input"
            margin="dense"
            id="confirmText"
            label="confirm"
            type="text"
            value={confirmText}
            name="confirmText"
            onChange={(e) => handleConfirmTextUpdate(e)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={dialogClose}
            color="secondary"
            variant="contained"
            data-cy="confirm-cancel"
          >
            Cancel
          </Button>
          {confirmText.toLowerCase() === "confirm" ? (
            <Button
              onClick={makeChanges}
              color="primary"
              variant="contained"
              data-cy="confirm-submit"
            >
              Confirm
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      {/*Delete Account Dialog*/}
      <Dialog
        open={openDeleteConfirm}
        onClose={dialogClose}
        aria-labelledby="confirm-delete-dialog"
      >
        <DialogTitle id="delete-dialog-title">Delete Your Account?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Remember... this is a one-way ticket back to Kansas. Once you
            confirm delete below we will irretrievably delete all data we hold.
          </DialogContentText>
          <DialogContentText>
            <strong>
              If you have a subscription you agree that there are no refunds for
              unused subscription time; but we will ask Stripe to cancel any
              future billing.
            </strong>
          </DialogContentText>
          <DialogContentText>
            If you want to continue, please type 'confirm' in the box below.
          </DialogContentText>
          <TextField
            margin="dense"
            id="confirmText"
            label="confirm"
            type="text"
            value={confirmText}
            name="confirmText"
            onChange={(e) => handleConfirmTextUpdate(e)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose} color="primary" variant="contained">
            No! Please don't delete.
          </Button>
          {confirmText.toLowerCase() === "confirm" ? (
            <MuiThemeProvider theme={redTheme}>
              <Button
                onClick={deleteAccount}
                color="primary"
                variant="contained"
              >
                I understand! Delete my Account.
              </Button>
            </MuiThemeProvider>
          ) : null}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const styles = (theme) => ({
  headers: {
    marginTop: theme.spacing(3),
  },
  bodyText: {
    marginTop: theme.spacing(1),
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  hintText: {
    color: "darkgrey",
  },
});

export default withStyles(styles)(UserSettings);
