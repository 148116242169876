import React from "react";
import { Typography, Paper } from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import LegalContact from "./LegalContact";

function Privacy(props) {
  const { classes } = props;

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography variant="h6">Application Privacy Policy</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          <p>
            Please take the time to read this privacy policy. By using Our
            Website and the Services and information offered on Our Website, you
            are agreeing to these policies..
          </p>
          <p>
            <strong>
              <u>We respect your privacy</u>
            </strong>
          </p>
          <p>
            ANECSO Pty Ltd respects your right to privacy and is committed to
            safeguarding the privacy of our customers and software application
            users. We adhere to the National Privacy Principles established by
            the Privacy Act 1988 (Cth). This policy sets out how we collect and
            treat your personal information.
          </p>
          <p>
            "Personal information" is information we hold which is identifiable
            as being about you.
          </p>
          <p>
            <strong>
              <u>Collection of personal information</u>
            </strong>
          </p>
          <p>
            ANECSO Pty Ltd will, from time to time, receive and store personal
            information you enter onto our software applications including, but
            not limited to, Badass ACIM, provide to us directly or give to us in
            other forms.
          </p>
          <p>
            You may provide basic information such as your name, phone number,
            address and email address to enable us to send information, provide
            updates and process your product or service order. We may collect
            additional information at other times, including but not limited to,
            when you provide feedback, when you provide information about your
            personal or business affairs, change your content or email
            preference, respond to surveys and/or promotions, provide financial
            or credit card information, or communicate with our customer
            support.
          </p>
          <p>
            Additionally, we may also collect any other information you provide
            while interacting with us.
          </p>
          <p>
            <strong>
              <u>How we collect your personal information</u>
            </strong>
          </p>
          <p>
            ANECSO Pty Ltd collects personal information from you in a variety
            of ways, including when you interact with us electronically or in
            person, when you access our software application and when we provide
            our services to you. We may receive personal information from third
            parties. If we do, we will protect it as set out in this Privacy
            Policy.
          </p>
          <p>
            <strong>
              <u>Use of your personal information</u>
            </strong>
          </p>
          <p>
            ANECSO Pty Ltd may use personal information collected from you to
            provide you with information, updates and our services. We may also
            make you aware of new and additional products, services and
            opportunities available to you. We may use your personal information
            to improve our products and services and better understand your
            needs.
          </p>
          <p>
            The Application may make third party social media features available
            to its users. We cannot ensure the security of any information you
            choose to make public in a social media feature. Also, we cannot
            ensure that parties who have access to such publicly available
            information will respect your privacy. ANECSO Pty Ltd may contact
            you by a variety of measures including, but not limited to
            telephone, email, sms or mail.
          </p>
          <p>
            <strong>
              <u>Disclosure of your personal information</u>
            </strong>
          </p>
          <p>
            We may disclose your personal information to any of our employees,
            officers, insurers, professional advisers, agents, suppliers or
            subcontractors insofar as reasonably necessary for the purposes set
            out in this Policy. Personal information is only supplied to a third
            party when it is required for the delivery of our services.
          </p>
          <p>
            We may from time to time need to disclose personal information to
            comply with a legal requirement, such as a law, regulation, court
            order, subpoena, warrant, in the course of a legal proceeding or in
            response to a law enforcement agency request.
          </p>
          <p>
            We may also use your personal information to protect the copyright,
            trademarks, legal rights, property or safety of ANECSO Pty Ltd, its
            application, website and customers or third parties.
          </p>
          <p>
            Information that we collect may from time to time be stored,
            processed in or transferred between parties located in countries
            outside of Australia.
          </p>
          <p>
            If there is a change of control in our business or a sale or
            transfer of business assets, we reserve the right to transfer to the
            extent permissible at law our user databases, together with any
            personal information and non-personal information contained in those
            databases. This information may be disclosed to a potential
            purchaser under an agreement to maintain confidentiality. We would
            seek to only disclose information in good faith and where required
            by any of the above circumstances.
          </p>
          <p>
            By providing us with personal information, you consent to the terms
            of this Privacy Policy and the types of disclosure covered by this
            Policy. Where we disclose your personal information to third
            parties, we will request that the third party follow this Policy
            regarding handling your personal information.
          </p>
          <p>
            <strong>
              <u>Security of your personal information</u>
            </strong>
          </p>
          <p>
            ANECSO Pty Ltd is committed to ensuring that the information you
            provide to us is secure. In order to prevent unauthorised access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures to safeguard and secure information and
            protect it from misuse, interference, loss and unauthorised access,
            modification and disclosure.{" "}
          </p>
          <p>
            The transmission and exchange of information is carried out at your
            own risk. We cannot guarantee the security of any information that
            you transmit to us, or receive from us. Although we take measures to
            safeguard against unauthorised disclosures of information, we cannot
            assure you that personal information that we collect will not be
            disclosed in a manner that is inconsistent with this Privacy Policy.
          </p>
          <p>
            <strong>
              <u>Access to your personal information</u>
            </strong>
          </p>
          <p>
            You may request details of personal information that we hold about
            you in accordance with the provisions of the Privacy Act 1988(Cth).
            A small administrative fee may be payable for the provision of
            information. If you would like a copy of the information which we
            hold about you or believe that any information we hold on you is
            inaccurate, out of date, incomplete, irrelevant or misleading,
            please contact us using the contact form below.
          </p>
          <p>
            We reserve the right to refuse to provide you with information that
            we hold about you, in certain circumstances set out in the Privacy
            Act.{" "}
          </p>
          <p>
            <strong>
              <u>Complaints about privacy</u>
            </strong>
          </p>
          <p>
            If you have any complaints about our privacy practices, please feel
            free to send in details of your complaints to PO Box 683, Kingston,
            Tasmania, 7051, Australia. We take complaints very seriously and
            will respond shortly after receiving written notice of your
            complaint.{" "}
          </p>
          <p>
            <strong>
              <u>Opt out right</u>
            </strong>
          </p>
          <p>
            You can stop all collection of information by the Application easily
            by ceasing to use and/or uninstalling the Application. You may use
            the standard uninstall processes as may be available as part of your
            mobile device or via the mobile application marketplace or network.
          </p>
          <p>
            <strong>
              <u>Changes to Privacy Policy</u>
            </strong>
          </p>
          <p>
            Please be aware that we may change this Privacy Policy in the
            future. We may modify this Policy at any time, in our sole
            discretion and all modifications will be effective immediately upon
            our posting of the modifications on our website or notice board.
            Please check back from time to time to review our Privacy Policy.{" "}
          </p>
          <p>
            <strong>
              <u>Software Application</u>
            </strong>
          </p>
          <p>
            When you come to our application we may collect certain information
            such as operating system, mobile unique device ID, the IP address,
            the type of internet browsers you use, and information about the way
            you use the Application. This information is used in an aggregated
            manner to analyse how people use our site, such that we can improve
            our service.
          </p>
          <p>
            We may from time to time use cookies on our software application.
            Cookies are very small files which a website uses to identify you
            when you come back to the application and to store details about
            your use of the application. Cookies are not malicious programs that
            access or damage your computer, tablet or smartphone. Most devices
            automatically accept cookies but you can choose to reject cookies by
            changing your device settings. However, this may prevent you from
            taking full advantage of our application.
          </p>
          <p>
            The software Application may collect certain information
            automatically, including, but not limited to, the type of device you
            use, identifying information about the device, the IP address, your
            operating system, the type of internet browsers you use, and
            information about the way you use the Application.
          </p>
          <p>
            Our software application may from time to time have links to other
            applications or websites not owned or controlled by us. These links
            are meant for your convenience only. Links to third party
            applications and websites do not constitute sponsorship or
            endorsement or approval of these third parties. Please be aware that
            ANECSO Pty Ltd is not responsible for the privacy practises of other
            such applications or websites. We encourage our users to be aware,
            when they leave our application or website, to read the privacy
            statements of each and every application or website that collects
            personal identifiable information.
          </p>
          <p>
            When you visit the application, we may use GPS technology (or other
            similar technology) to determine your current location in order to
            determine the city you are located within and display a location map
            with relevant advertisements. We will not share your current
            location with other users or partners.{" "}
          </p>
          <p>
            <strong>
              <u>Governing Law</u>
            </strong>
          </p>
          <p>
            These privacy policies are governed by and construed in accordance
            with the laws of Tasmania, Australia. Any disputes concerning these
            matters are to be resolved by the courts having jurisdiction in
            Tasmania.
          </p>
          <p>
            <strong>
              <u>Queries</u>
            </strong>
          </p>
          <p>Can be made by using the contact form in the help widget.</p>
        </Typography>
        {/*<LegalContact />*/}
      </Paper>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

export default withRouter(withStyles(styles)(Privacy));
