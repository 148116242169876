import React, { useState, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import withStyles from "@material-ui/core/styles/withStyles";
import { AuthContext } from "../../providers/Auth";
import app from "../../config/firebase";

import HomeIcon from "@material-ui/icons/Home";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import HistoryIcon from "@material-ui/icons/History";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";

function Navbar(props) {
  const { classes } = props;
  const history = useHistory();
  const [drawOpen, setDrawerOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const toggleDraw = (boolean) => (event) => {
    setDrawerOpen(boolean);
  };

  const list = () => (
    <div>
      <List>
        <ListItem>
          <Button
            startIcon={<HomeIcon />}
            data-cy="home-link"
            onClick={() => {
              history.push("/");
              setDrawerOpen(false);
            }}
          >
            Home
          </Button>
        </ListItem>
        {currentUser ? (
          <ListItem>
            <Button
              startIcon={<MenuBookIcon />}
              data-cy="daily-lesson-link"
              onClick={() => {
                history.push("/daily-lesson");
                setDrawerOpen(false);
              }}
            >
              Daily Lesson
            </Button>
          </ListItem>
        ) : null}
        {currentUser ? (
          <ListItem>
            <Button
              startIcon={<HistoryIcon />}
              data-cy="lesson-history-link"
              onClick={() => {
                history.push("/lesson-history");
                setDrawerOpen(false);
              }}
            >
              Lesson History
            </Button>
          </ListItem>
        ) : null}
        {/*{currentUser ? <ListItem>*/}
        {/*    <Button startIcon={<FavoriteIcon />} data-cy="lesson-favourites-link" onClick={() => {history.push("/lesson-favourites"); setDrawerOpen(false)}}>Lesson Favourites</Button>*/}
        {/*</ListItem> : null}*/}
        {currentUser ? (
          <ListItem>
            <Button
              startIcon={<SettingsIcon />}
              data-cy="settings-link"
              onClick={() => {
                history.push("/settings");
                setDrawerOpen(false);
              }}
            >
              Settings
            </Button>
          </ListItem>
        ) : null}
        <ListItem>
          <Button
            startIcon={<LocalFloristIcon />}
            data-cy="about-pam-link"
            onClick={() => {
              history.push("/about-pam");
              setDrawerOpen(false);
            }}
          >
            About Pam
          </Button>
        </ListItem>
        {/*{currentUser ? <ListItem>*/}
        {/*    <Button startIcon={<ContactSupportIcon/>} data-cy="app-support-link" onClick={() => {history.push("/app-support"); setDrawerOpen(false)}}>App Support</Button>*/}
        {/*</ListItem> : null}*/}
        {!currentUser ? (
          <ListItem>
            <Button
              startIcon={<VerifiedUserIcon />}
              data-cy="login-link"
              onClick={() => {
                history.push("/login");
                setDrawerOpen(false);
              }}
            >
              Login
            </Button>
          </ListItem>
        ) : null}
        {!currentUser ? (
          <ListItem>
            <Button
              startIcon={<HowToRegIcon />}
              data-cy="register-link"
              onClick={() => {
                history.push("/register");
                setDrawerOpen(false);
              }}
            >
              Register
            </Button>
          </ListItem>
        ) : null}
      </List>
    </div>
  );

  const list2 = () => (
    <div className={classes.bottomList}>
      <List>
        <Divider variant="middle" />
        <ListItem
          button
          data-cy="privacy-link"
          onClick={() => {
            history.push("/legals/privacy");
            setDrawerOpen(false);
          }}
        >
          <Typography variant="caption">Privacy</Typography>
        </ListItem>
        <ListItem
          button
          data-cy="terms-link"
          onClick={() => {
            history.push("/legals/terms");
            setDrawerOpen(false);
          }}
        >
          <Typography variant="caption">Terms</Typography>
        </ListItem>
        {currentUser ? (
          <ListItem>
            <Button
              startIcon={<VerifiedUserIcon />}
              data-cy="signout-link"
              onClick={() => {
                app.auth().signOut();
                history.replace("/");
                setDrawerOpen(false);
              }}
            >
              Logout
            </Button>
          </ListItem>
        ) : null}
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            data-cy="hamburgerMenu"
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDraw(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Badass ACIM
          </Typography>
          {currentUser ? (
            <div>
              <Button
                data-cy="logout-header-button"
                color="inherit"
                onClick={() => {
                  app.auth().signOut();
                  history.replace("/");
                }}
              >
                Logout
              </Button>
            </div>
          ) : (
            <Button
              data-cy="login-header-button"
              color="inherit"
              onClick={() => {
                history.push("/login");
              }}
            >
              Login
            </Button>
          )}
        </Toolbar>
        <SwipeableDrawer
          anchor={"left"}
          open={drawOpen}
          onOpen={toggleDraw(true)}
          onClose={toggleDraw(false)}
        >
          <div className={classes.listContainer}>
            {list()}
            {list2()}
          </div>
        </SwipeableDrawer>
      </AppBar>
    </div>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  listContainer: {
    position: "relative",
    height: "100%",
  },
  bottomList: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(Navbar);
