import React from "react";
import { Typography, Paper } from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import LegalContact from "./LegalContact";

function Terms(props) {
  const { classes } = props;

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography variant="h6">Terms of Service</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          <p>
            Please take the time to read these terms and conditions. By using
            Our Website and the Services and information offered on Our Website,
            you are agreeing to these terms and conditions.
          </p>
          <p>
            <strong>
              <u>Definitions</u>
            </strong>
          </p>
          <p>
            <strong>Services</strong> means website subscription services and
            content located at Badass ACIM (https://badass-acim.com) and any
            subdomains.
          </p>
          <p>
            <strong>the Website</strong> means the website
            https://badass-acim.com and any subdomains.
          </p>
          <p>
            <strong>We / Us</strong> etc means ANECSO Pty Ltd and any
            subsidiaries, affiliates, employees, officers, agents or assigns.
          </p>
          <p>
            <strong>
              <u>Accuracy of content</u>
            </strong>
          </p>
          <p>
            We have taken proper care and precautions to ensure that the
            information we provide on this Website is accurate. However, we
            cannot guarantee, nor do we accept any legal liability arising from
            or connected to, the accuracy, reliability, currency or completeness
            of anything contained on this Website or on any linked site.
          </p>
          <p>
            The information contained on this Website should not take the place
            of professional advice.
          </p>
          <p>
            <strong>
              <u>Use</u>
            </strong>
          </p>
          <p>
            The Website is made available for your use on your acceptance and
            compliance with these terms and conditions. By using this Website,
            you are agreeing to these terms and conditions.
          </p>
          <p>
            You agree that you will use this website in accordance with all
            applicable local, state, national and international laws, rules and
            regulations.
          </p>
          <p>
            You agree that you will not use, nor will you allow or authorise any
            third party to use, the Website for any purpose that is unlawful,
            defamatory, harassing, abusive, fraudulent or obscene way or in any
            other inappropriate way or in a way which conflicts with the Website
            or the Services.
          </p>
          <p>
            If you contribute to our forum (if any) or make any public comments
            on this Website which are, in our opinion, unlawful, defamatory,
            harassing, abusive, fraudulent or obscene or in any other way
            inappropriate or which conflict with the Website or the Services
            offered, then we may at our discretion, refuse to publish such
            comments and/or remove them from the Website.
          </p>
          <p>
            We reserve the right to refuse or terminate service to anyone at any
            time without notice or reason.
          </p>
          <p>
            <strong>
              <u>Passwords and logins</u>
            </strong>
          </p>
          <p>
            You are responsible for maintaining the confidentiality of your
            passwords and login details and for all activities carried out under
            your password and login.
          </p>
          <p>
            <strong>
              <u>Indemnification for loss or damage</u>
            </strong>
          </p>
          <p>
            You agree to indemnify Us and hold Us harmless from and against any
            and all liabilities or expenses arising from or in any way related
            to your use of this Website or the Services or information offered
            on this Website, including any liability or expense arising from all
            claims, losses, damages (actual and consequential), suits,
            judgments, litigation costs and solicitors fees of every kind and
            nature incurred by you or any third parties through you.
          </p>
          <p>
            <strong>
              <u>Intellectual property and copyrights</u>
            </strong>
          </p>
          <p>
            We hold the copyright to the content of this Website, including all
            uploaded files, layout design, data, graphics, articles, file
            content, codes, news, tutorials, videos, reviews, forum posts and
            databases contained on the Website or in connection with the
            Services. You must not use or replicate our copyright material other
            than as permitted by law. Specifically, you must not use or
            replicate our copyright material for commercial purposes unless
            expressly agreed to by Us, in which case we may require you to sign
            a Licence Agreement.
          </p>
          <p>
            If you wish to use content, images or other of our intellectual
            property, you should submit your request to us using the contact
            form below.
          </p>
          <p>
            <strong>
              <u>Trademarks</u>
            </strong>
          </p>
          <p>
            The trademarks and logos contained on this Website are trademarks of
            ANECSO Pty Ltd. Use of these trademarks is strictly prohibited
            except with Our express, written consent.
          </p>
          <p>
            <strong>
              <u>Links to external websites</u>
            </strong>
          </p>
          <p>
            This Website may contain links that direct you outside of this
            Website. These links are provided for your convenience and are not
            an express or implied indication that we endorse or approve of the
            linked Website, it’s contents or any associated website, product or
            service. We accept no liability for loss or damage arising out of or
            in connection to your use of these sites.
          </p>
          <p>
            You may link to our articles or home page. However, you should not
            provide a link which suggests any association, approval or
            endorsement on our part in respect to your website, unless we have
            expressly agreed in writing. We may withdraw our consent to you
            linking to our site at any time by notice to you.
          </p>
          <p>
            <strong>
              <u>Limitation of Liability</u>
            </strong>
          </p>
          <p>
            We take no responsibility for the accuracy of any of the content or
            statements contained on this Website or in relation to our Services.
            Statements made are by way of general comment only and you should
            satisfy yourself as to their accuracy. Further, all of our Services
            are provided without a warranty with the exception of any warranties
            provided by law. We are not liable for any damages whatsoever,
            incurred as a result of or relating to the use of the Website or our
            Services.
          </p>
          <p>
            <strong>
              <u>Information Collection</u>
            </strong>
          </p>
          <p>
            Use of information you have provided us with, or that we have
            collected and retained relating to your use of the Website and/or
            our Services, is governed by our Privacy Policy. By using this
            Website and the Services associated with this Website, you are
            agreeing to the Privacy Policy. To view our Privacy Policy and read
            more about why we collect personal information from you and how we
            use that information, <Link to={"/legals/privacy"}>click here</Link>
            .
          </p>
          <p>
            <strong>
              <u>Confidentiality</u>
            </strong>
          </p>
          <p>
            All personal information you give us will be dealt with in a
            confidential manner in accordance with our Privacy Policy. However,
            due to circumstances outside of our control, we cannot guarantee
            that all aspects of your use of this Website will be confidential
            due to the potential ability of third parties to intercept and
            access such information.
          </p>
          <p>
            <strong>
              <u>Governing Law</u>
            </strong>
          </p>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of Tasmania, Australia. Any disputes
            concerning this website are to be resolved by the courts having
            jurisdiction in Tasmania.
          </p>
          <p>
            We retain the right to bring proceedings against you for breach of
            these Terms and Conditions, in your country of residence or any
            other appropriate country or jurisdiction.
          </p>
          <p>
            <strong>
              <u>Queries</u>
            </strong>
          </p>
          <p>Can be made by using the contact form in the help widget.</p>
        </Typography>

        {/*<LegalContact />*/}
      </Paper>
    </main>
  );
}

const styles = (theme) => ({
  main: {
    width: "90%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

export default withRouter(withStyles(styles)(Terms));
