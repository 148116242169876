import React, { Fragment } from "react";
import {
  Typography,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  DialogTitle,
  DialogContent,
  ListItemText,
} from "@material-ui/core";

import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import AirplanemodeInactiveIcon from "@material-ui/icons/AirplanemodeInactive";
import GetAppIcon from "@material-ui/icons/GetApp";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CodeIcon from "@material-ui/icons/Code";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import WebIcon from "@material-ui/icons/Web";

function PWAInstallation(props) {
  const { classes } = props;

  const chromeUrl =
    "https://support.google.com/chrome/answer/9658361?co=GENIE.Platform%3DDesktop&hl=en";
  const firefoxUrl =
    "https://blog.mozilla.org/firefox/progressive-web-apps-whats-big-deal/";
  const edgeUrl =
    "https://docs.microsoft.com/en-us/microsoft-edge/progressive-web-apps-chromium/";
  const articleUrl =
    "https://mobilesyrup.com/2020/05/24/how-install-progressive-web-app-pwa-android-ios-pc-mac/";

  return (
    <Fragment>
      <DialogTitle id={"pwa-alert-title"}>
        How can I install this app?
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="pwa-content1"
        >
          Installing the App is simplicity itself. There are three (3) options
          for installation.
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <AndroidIcon />
            </ListItemIcon>
            <ListItemText>
              Android Devices - Please go to the{" "}
              <a
                href={
                  "https://play.google.com/store/apps/details?id=solutions.risen.badass_acim"
                }
                target="__blank"
                rel="noopener noreferrer"
              >
                Play Store
              </a>
              .
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AppleIcon />
            </ListItemIcon>
            <ListItemText>Apple Devices - Coming Soon!</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText>Progressive Web App - See below.</ListItemText>
          </ListItem>
        </List>
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="pwa-content1"
        >
          A subscription purchased on ANY platform will work on all the others.
          So, for instance, you purchased your subscription through Google Play
          then you can use the IOS app or Progressive Web App too... the
          subscription will seamlessly follow you around if you use the same
          login credentials.
        </Typography>
        <Typography
          variant="h6"
          className={classes.bodyText}
          data-cy="pwa-content1"
        >
          Installing the Progressive Web App
        </Typography>
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="pwa-content1"
        >
          In addition to the 'Native Apps', this App has been released as a
          Progressive Web App (PWA) which is a type of software delivered
          through the web and is intended to work on any platform that uses a
          standards-compliant browser, including both desktop and mobile
          devices. The advantage of PWA's is that they;
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <AirplanemodeInactiveIcon />
            </ListItemIcon>
            <ListItemText>Work offline</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText>Can be installed on multiple devices</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <OfflineBoltIcon />
            </ListItemIcon>
            <ListItemText>Are high performance</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <NotificationsActiveIcon />
            </ListItemIcon>
            <ListItemText>
              Support push notifications (some IOS devices have issues due to
              Apple's policies)
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText>
              Are quick to release updates (so we can quickly make the App more
              AWESOME for you)
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="body1" className={classes.bodyText}>
          In order to get the best from a PWA you can install it as an App on
          your mobile device and/or desktop computer using your browser.
          Generally this is done using the browser settings menu; but of course
          every browser does this slightly differently making it hard for us to
          provide advice on the exact instructions (without making this note
          ridiculously long). If you need guidance, then this{" "}
          <a
            href={
              "https://mobilesyrup.com/2020/05/24/how-install-progressive-web-app-pwa-android-ios-pc-mac/"
            }
            target={"_blank no"}
            rel={"noopener noreferrer"}
          >
            excellent article by Jonathan Lamont
          </a>{" "}
          has great examples for different devices.
        </Typography>
        <Typography
          variant="body1"
          className={classes.bodyText}
          data-cy="pwa-content2"
        >
          Once installed you will be able to interact with the App just like
          something installed from the Store including using it offline by
          choosing offline support (hint: see the settings area). Remember, your
          login and subscription will work with the PWA AND the Mobile Apps.
        </Typography>
      </DialogContent>
    </Fragment>
  );
}

const styles = (theme) => ({
  main: {
    width: "100%",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  bodyText: {
    "& p:not(:first-child)": {
      margin: "16px 0px",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(PWAInstallation);
